<template>
  <el-dialog title="跟单"
             :close-on-click-modal='false'
             width="30%"
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="身份证号码">
        <el-input v-model="form.dentification_number"></el-input>
      </el-form-item>
      <el-form-item label="跟单状态"
                    prop="documentary_status">
        <el-radio-group v-model="form.documentary_status">
          <el-radio label="success">成功</el-radio>
          <el-radio label="failure">失败</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>

import { setAppointCustomer } from '@/api/market'
export default {
  props: {
  },
  data () {
    return {
      form: {
        activity_id: '',
        id: '',
        dentification_number: '',
        documentary_status: ''
      },
      rules: {
        dentification_number: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' }
        ],
        documentary_status: [
          { required: true, message: '请选择跟单状态', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false
    }
  },
  components: {
  },
  methods: {
    handleClose () { },
    showModal (formData, aid) {
      this.dialogVisible = true
      if (formData) {
        this.form = Object.assign({}, formData)
        this.form.activity_id = aid
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setAppointCustomer(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '跟单成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
