<template>
  <div>
    <table-page dataName="customers"
                ref="table"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :request="fetchAppointCustomer">
      <template slot="button">
        <!-- <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="exportUrl"
                   @click="exportData">导出领取记录</el-button> -->
        <FileExport :params="exportParams">导出领取记录</FileExport>
      </template>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="OPENID"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>
      <el-table-column prop="status"
                       show-overflow-tooltip
                       label="领取状态">
      </el-table-column>
      <el-table-column prop="receive_at"
                       show-overflow-tooltip
                       label="领取时间">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="领取金额">
        <template slot-scope="scope">
          {{scope.row.receive_money/100+'元'|| ''}}
        </template>
      </el-table-column>
      <el-table-column prop="areas_name"
                       show-overflow-tooltip
                       label="所选网点">
      </el-table-column>
      <el-table-column label="跟单状态">
        <template slot-scope="scope">
          <span v-if="scope.row.documentary_status ==='success'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>成功
          </span>
          <span v-else-if="scope.row.documentary_status ==='failure'">
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>失败
          </span>
          <span v-else>
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>未跟单
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="documentary_at"
                       show-overflow-tooltip
                       label="跟单时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$refs.setStatus.showModal(scope.row,$route.query.id)">跟单</el-button>
        </template>

      </el-table-column>
    </table-page>
    <SetStatus ref="setStatus"
               @on-success="$refs.table.fetch()" />
  </div>

</template>

<script>
import TablePage from '@/components/TablePage'
import FileExport from '@/components/FileExport'
import { fetchAppointCustomer } from '@/api/market'
import SetStatus from './components/SetStatus'
export default {
  data () {
    return {
      exportParams: null,
      fetchAppointCustomer,
      dialogVisible: false,
      paramsObj: {
      },
      exportUrl: ''
    }
  },
  components: {
    TablePage,
    SetStatus,
    FileExport
  },
  methods: {
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    showTable (id) {
      this.dialogVisible = true
      this.paramsObj = {
        activity_id: id
      }
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
      this.exportParams = e.export
    },
    exportData () {
      window.open(this.exportUrl, '_blank')
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
